import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/styles/withStyles';

import { FormHelperText } from '@lc/legacy/src/Components';
import EmailOutlined from '@material-ui/icons/EmailOutlined';

import * as yup from 'yup';

export const emailValidateSchema = yup
  .string()
  .email()
  .required();

const EmailTextField = ({
  classes,
  className,
  showIcon,
  register,
  label,
  error,
  value,
  onChange,
  ...otherProps
}) => {
  const { t } = useTranslation();

  return (
    <>
      <TextField
        fullWidth
        className={clsx(classes.input, className)}
        label={label || t('emailAddress')}
        name="email"
        type="email"
        onChange={onChange}
        value={value}
        style={{ marginBottom: '20px' }}
        inputRef={register({ required: true, email: true })}
        InputProps={{
          endAdornment: !showIcon ? (
            undefined
          ) : (
            <InputAdornment position="end">
              <EmailOutlined />
            </InputAdornment>
          ),
        }}
        {...otherProps}
      />
      <FormHelperText type="small" error={!!error} message={t('emptyEmailAddressFieldWarning')} />
    </>
  );
};

const styles = theme => ({
  input: {
    '& input, & label': {
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(16),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(12),
      },
    },
    '& svg': {
      width: '22px',
      marginRight: theme.spacing(2),
      color: 'hsla(0, 0%, 20%, .5)',
    },
  },
});

EmailTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  error: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
  register: PropTypes.func.isRequired,
  showIcon: PropTypes.bool,
  value: PropTypes.string,
};

EmailTextField.defaultProps = {
  className: undefined,
  error: undefined,
  value: undefined,
  label: undefined,
  onChange: undefined,
  showIcon: true,
};

export default withStyles(styles)(EmailTextField);
