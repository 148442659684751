import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';

const Wizard = ({ scenes, urlName, whenBack, whenDone, statusProgress, progressCallback }) => {
  const [currentScene, setCurrentScene] = useState(0);
  const totalScenes = scenes.length - 1;

  const onNext = async canGo => {
    const cg = await canGo();
    if (!cg) return;
    if (currentScene + 1 > totalScenes) {
      whenDone();
    } else {
      setCurrentScene(currentScene + 1);
    }
  };

  const onBack = canGo => {
    if (!canGo()) return;
    if (currentScene - 1 < 0) {
      whenBack();
    } else {
      // Router.back()
      setCurrentScene(currentScene - 1);
    }
  };

  const loadCurrentScene = step => {
    const currScene = step - 1;
    if (currScene <= totalScenes && currScene >= 0) {
      setCurrentScene(currScene);
      return true;
    }
    return false;
  };

  useEffect(() => {
    statusProgress(currentScene + 1, totalScenes + 1);
    progressCallback(currentScene, totalScenes);
    if (urlName === undefined) return;
    const link = `${urlName}?step=${currentScene + 1}`;
    if (currentScene + 1 === 1) {
      Router.replace(link, link, { shallow: true });
    } else {
      Router.push(link, link, { shallow: true });
    }
  }, [currentScene, totalScenes]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (urlName === undefined) return;
      const { step } = Router.query;
      const c404 = loadCurrentScene(step);
      if (!c404) console.log('step não encontrado, deve-se tratar algo aqui', step);
    };

    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [loadCurrentScene]);

  return <>{scenes[currentScene]({ onBack, onNext })}</>;
};

Wizard.propTypes = {
  progressCallback: PropTypes.func,
  scenes: PropTypes.arrayOf(PropTypes.elementType).isRequired,
  statusProgress: PropTypes.func,
  urlName: PropTypes.string,
  whenBack: PropTypes.func.isRequired,
  whenDone: PropTypes.func.isRequired,
};
Wizard.defaultProps = {
  progressCallback: () => {},
  statusProgress: () => {},
  urlName: undefined,
};

export default Wizard;
