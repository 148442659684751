import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { useTheme, withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { Grid, useMediaQuery } from '@material-ui/core';

import InputField from '@lc/legacy/src/Components/InputField';
import Button from '@lc/legacy/src/Components/Button';

import Link from 'components/Link';

const EmailForm = ({ classes, passwordModal, validateEmail, handleLoginWithEmail }) => {
  const { t } = useTranslation(['forms', 'translations']);
  const xsSize = useMediaQuery(useTheme().breakpoints.down('xs'));

  const { register, errors, setError, clearError, getValues } = useForm();

  const initialEmailForm = { email: '' };

  const emailInput = useRef();
  const passwordInput = useRef();

  const [validating, setValidating] = useState(false);
  const [emailForm, setEmailForm] = useState(initialEmailForm);

  const submit = async () => {
    const { email, password } = getValues();

    clearError('email', 'password');
    if (!emailForm.email && !email) {
      setError('email');
      return false;
    }
    if (!emailForm.email) {
      setValidating(true);
      const exist = await validateEmail(email);
      if (exist) {
        setValidating(false);
        setEmailForm({ ...emailForm, email });
        passwordInput.current.focus();
      }
      setValidating(false);
      return false;
    }
    if (!password) {
      setError('password');
      return false;
    }

    if (emailForm.email) {
      await handleLoginWithEmail({ email: emailForm.email, password });
      return false;
    }
    setValidating(false);
    return false;
  };

  useEffect(() => {
    if (emailInput.current) {
      emailInput.current.focus();
      emailInput.current.value = '';
    }
  }, [emailForm]);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        return submit();
      }}
      autoComplete="off"
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputField
            style={{ display: emailForm.email ? 'none' : 'block' }}
            variant="outlined"
            data-testid="form-neighborhood"
            label={t('forms:emailField')}
            name="email"
            type="email"
            error={!!errors.email}
            fullWidth
            className={classes.inputField}
            inputProps={{
              ref: ref => {
                emailInput.current = ref;
                return register({ required: true })(ref);
              },
            }}
          />

          <InputField
            style={{ display: !emailForm.email ? 'none' : 'block' }}
            variant="outlined"
            data-testid="form-neighborhood"
            label={t('translations:password')}
            name="password"
            type="password"
            error={!!errors.password}
            fullWidth
            className={classes.inputField}
            inputProps={{
              ref: ref => {
                passwordInput.current = ref;
                return register({ required: true })(ref);
              },
            }}
          />
        </Grid>
        {emailForm.email && (
          <>
            <Grid item xs={6}>
              <Typography variant={xsSize ? 'caption' : 'button'}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link href="#" onClick={passwordModal}>
                  {t('translations:forgetPassword')}
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={xsSize ? 'caption' : 'button'}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link href="#" onClick={() => setEmailForm(initialEmailForm)}>
                  Trocar de email
                </Link>
              </Typography>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Button
            loading={validating}
            variant="contained"
            color="primary"
            fullWidth
            size={xsSize ? 'small' : 'medium'}
            type="submit"
          >
            {emailForm.email ? 'Entrar' : 'Continuar'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

EmailForm.propTypes = {
  classes: PropTypes.object.isRequired,
  validateEmail: PropTypes.func.isRequired,
  handleLoginWithEmail: PropTypes.func.isRequired,
  passwordModal: PropTypes.func.isRequired,
};

const styles = () => ({
  inputField: {
    '& > label': { fontSize: '16px !important' },
  },
});

export default withStyles(styles)(EmailForm);
