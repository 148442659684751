export default {
  login: '/login',
  signupForm: '/signup-form',
  home: '/',
  hosterHome: '/compartilhar-consultorio',
  booking: id => `/booking/${id}`,
  bookingDetail: id => `/booking/details/${id}`,
  bookingPaymentMethod: id => `/booking/payment/${id}`,
  bookingPaymentCC: id => `/booking/payment/cc/${id}`,
  bookingPaymentPix: id => `/booking/payment/pix/${id}`,
  search: '/alugar-consultorios',
  searchByExpertise: expertise => `/alugar-consultorios/${expertise}`,
};
