import React from 'react';
import { useRouter } from 'next/router';

import Login from 'features/Login';

const LoginIndex = () => {
  const router = useRouter();
  return <Login query={router.query} />;
};

export default LoginIndex;
