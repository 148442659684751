const styles = theme => ({
  joinTitle: {
    paddingBottom: theme.spacing(2),
  },
  signupField: {
    margin: theme.spacing(1, 0),
  },
  signupButton: {
    margin: theme.spacing(3, 0),
  },
});

export default styles;
