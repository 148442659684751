const styles = theme => ({
  buttonsContainer: {
    maxWidth: '520px',
    padding: theme.spacing(4, 1, 0, 1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(8, 9, 2.5, 9),
    },
    textAlign: 'center',
  },
  buttonsTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
  },
  buttonsSeparator: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(3, 0),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '256px',
      margin: theme.spacing(2, 'auto'),
    },
  },
  line: {
    flex: 1,
    display: 'flex',
    backgroundColor: 'hsla(0, 0%, 65%, 1)',
  },
  signupMessageContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  signupMessage: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.lightSecondary,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  signupButton: {
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.pxToRem(16),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12),
    },
    color: theme.palette.primary.main,
  },
});

export default styles;
