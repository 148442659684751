import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

import { useTheme, withStyles } from '@material-ui/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { useMediaQuery } from '@material-ui/core';

import { Google, Facebook, LinkedIn, Email } from 'components/SocialLoginButtons';

import { FACEBOOK_ID, SOCIAL_REDIRECT_URL, GOOGLE_ID, LINKEDIN_ID } from 'config/socialNetworks';

import Avatar from '@lc/legacy/src/Components/Avatar';

import EmailForm from './EmailForm';

import styles from '../index.style';

const LoginOptions = ({
  classes,
  validateEmail,
  handleLoginWithEmail,
  passwordModal,
  defaultLogin,
  setDefaultLogin,
  publicAccount,
}) => {
  const { query } = useRouter();
  const stateUrl = new URLSearchParams(query);
  const encodedUrl = encodeURIComponent(stateUrl.toString());
  const { t } = useTranslation(['forms', 'translations']);
  const xsSize = useMediaQuery(useTheme().breakpoints.down('xs'));

  const fbParams = new URLSearchParams();
  fbParams.set('client_id', FACEBOOK_ID);
  fbParams.set('redirect_uri', SOCIAL_REDIRECT_URL);
  fbParams.set('scope', 'email,public_profile');
  fbParams.set('state', `social=fblogin&&encodedUrl=${encodedUrl}`);

  const googleParams = new URLSearchParams();
  googleParams.set('client_id', GOOGLE_ID);
  googleParams.set('redirect_uri', SOCIAL_REDIRECT_URL);
  googleParams.set('scope', 'openid email https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile');
  googleParams.set('access_type', 'online');
  googleParams.set('include_granted_scopes', 'true');
  googleParams.set('response_type', 'code');
  googleParams.set('state', `social=googlelogin&encodedUrl=${encodedUrl}`);

  const linkedinParams = new URLSearchParams();
  linkedinParams.set('client_id', LINKEDIN_ID);
  linkedinParams.set('redirect_uri', SOCIAL_REDIRECT_URL);
  linkedinParams.set('scope', 'r_liteprofile,r_emailaddress');
  linkedinParams.set('response_type', 'code');
  linkedinParams.set('state', `social=linkedinlogin&encodedUrl=${encodedUrl}`);

  const [hide, setHide] = useState(['email']);

  useEffect(() => {
    setHide([defaultLogin]);
  }, [defaultLogin]);

  const GoogleButton = memo(() => (
    <Google
      text="Continuar com Google"
      href={`https://accounts.google.com/o/oauth2/v2/auth?${googleParams.toString()}`}
    />
  ));
  const FacebookButton = memo(() => (
    <Facebook
      text="Continuar com Facebook"
      href={`https://www.facebook.com/v12.0/dialog/oauth?${fbParams.toString()}`}
    />
  ));
  const LinkedInButton = memo(() => (
    <LinkedIn
      text="Continuar com LinkedIn"
      href={`https://www.linkedin.com/oauth/v2/authorization?${linkedinParams.toString()}`}
    />
  ));

  return (
    <>
      {defaultLogin === 'email' ? (
        <EmailForm
          passwordModal={passwordModal}
          validateEmail={validateEmail}
          handleLoginWithEmail={handleLoginWithEmail}
        />
      ) : (
        <>
          <Typography variant="body1">
            <b>Bem-vindo de volta, {publicAccount.firstName}</b>
          </Typography>

          <Avatar
            src={publicAccount.profilePictureUrl}
            alt="Foto de perfil"
            type="medium"
            style={{ marginTop: '20px', marginBottom: '20px' }}
          />
          {defaultLogin === 'google' ? <GoogleButton /> : null}
          {defaultLogin === 'facebook' ? <FacebookButton /> : null}
          {/* {defaultLogin === 'linkedin' ? <LinkedInButton /> : null} */}
        </>
      )}
      <div className={classes.buttonsSeparator}>
        <Divider className={classes.line} />
        <Typography variant={xsSize ? 'button' : 'body1'} style={{ margin: '0 10px' }}>
          {t('translations:orContinueWith')}
        </Typography>
        <Divider className={classes.line} />
      </div>
      <div style={{ gap: '16px', display: 'flex', flexDirection: 'column' }}>
        {hide.includes('google') ? null : <GoogleButton />}
        {hide.includes('facebook') ? null : <FacebookButton />}
        {/* {hide.includes('linkedin') ? null : <LinkedInButton />} */}
        {hide.includes('email') ? null : (
          <Email text="Continuar com Email" onClick={() => setDefaultLogin('email')} />
        )}
      </div>
    </>
  );
};

LoginOptions.propTypes = {
  classes: PropTypes.object.isRequired,
  publicAccount: PropTypes.object,
  validateEmail: PropTypes.func.isRequired,
  handleLoginWithEmail: PropTypes.func.isRequired,
  passwordModal: PropTypes.func.isRequired,
  defaultLogin: PropTypes.oneOf(['google', 'facebook', 'email']),
  setDefaultLogin: PropTypes.func.isRequired,
};

LoginOptions.defaultProps = {
  defaultLogin: 'email',
  publicAccount: undefined,
};

export default withStyles(styles)(LoginOptions);
