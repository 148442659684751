import React from 'react';
import PropTypes from 'prop-types';

import { withStyles, MobileStepper as MobileStepperMUI } from '@material-ui/core';
import styles from './index.style';

const MobileStepper = ({ classes, stepperOptions }) => (
    <MobileStepperMUI
      {...stepperOptions}
      variant="dots"
      position="static"
      className={classes.progressDots}
    />
  );

MobileStepper.propTypes = {
  classes: PropTypes.object.isRequired,
  stepperOptions: PropTypes.exact({
    steps: PropTypes.number,
    activeStep: PropTypes.number,
  }),
};

MobileStepper.defaultProps = {
  stepperOptions: {
    steps: 6,
    activeStep: 0,
  },
};

export default withStyles(styles)(MobileStepper);
