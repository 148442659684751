import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Router from 'next/router';

import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { url, session } from 'helpers';
import Modal from 'components/Modal';
import Wizard from 'components/Wizard';

/* Scenes */

import Welcome from './welcome';
import Photo from './photo';
import Phone from './phone';
import Document from './document';
import Address from './address';
import OccupationArea from './occupationArea';
import Email from './email';
import MobileStepper from '../../components/MobileStepper';

let scenes = [
  props => <Welcome {...props} />,
  props => <Photo {...props} />,
  props => <OccupationArea {...props} />, // mandatory
  props => <Phone {...props} />,
  props => <Document {...props} />,
  props => <Address {...props} />,
  props => <Email {...props} />,
];
let mandatoryScenesIndexes = [2]; // scenes
if (session.getMandatoryRegister()) {
  mandatoryScenesIndexes = [...scenes.keys()];
}

const Register = ({ isOpen }) => {
  const [currentWizardProgress, setCurrentWizardProgress] = useState({ current: 0, total: 0 });
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const xsSize = useMediaQuery(useTheme().breakpoints.between('xs', 'sm'));
  const smSize = useMediaQuery(useTheme().breakpoints.up('sm'));

  const totalScenes = scenes.length - 1;

  const isMandatory = () => mandatoryScenesIndexes.includes(currentWizardProgress.current);

  useEffect(() => {
    session.removeUserData();
    if (url.shouldRedirectToAnotherPage() === '/video') {
      scenes = [
        props => <Photo {...props} />,
        props => <OccupationArea {...props} />,
        props => <Phone {...props} />,
        props => <Email {...props} />,
      ];
      mandatoryScenesIndexes = [1]; // mandatory
    }
  }, []);

  return (
    isModalOpen && (
      <Modal
        styleBody={{
          paddingTop: '60px',
          paddingLeft: xsSize ? '15px' : '72px',
          paddingRight: xsSize ? '15px' : '72px',
          width: smSize ? '530px' : 'auto',
        }}
        disableBackdropClick={isMandatory()}
        closeButton={!isMandatory()}
        onClose={() => {
          setIsModalOpen(false);
          if (url.shouldRedirectToAnotherPage()) {
            Router.replace(url.shouldRedirectToAnotherPage());
          } else {
            Router.replace('/');
          }
        }}
        Header={
          currentWizardProgress.current > 0 && (
            <MobileStepper
              stepperOptions={{
                steps: totalScenes,
                activeStep: currentWizardProgress.current - 1,
              }}
            />
          )
        }
      >
        <Wizard
          ready
          scenes={scenes}
          progressCallback={(current, total) => {
            setCurrentWizardProgress({ current, total });
          }}
          whenDone={() => {
            if (url.shouldRedirectToAnotherPage()) {
              Router.replace(url.shouldRedirectToAnotherPage());
            } else {
              sessionStorage.setItem('canExplore', true);
              Router.replace('/');
            }
          }}
          whenBack={() => {}}
        />
      </Modal>
    )
  );
};

Register.propTypes = {
  hotsite: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
};

Register.defaultProps = {
  hotsite: false,
};

export default Register;
