import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import { Button } from '@lc/legacy/src/Components';

import Link from 'components/Link';
import Modal from 'components/Modal';
import styles from './index.style';

const DialogConfirm = ({ classes, onClose, onAccept, onCancel }) => {
  const { t } = useTranslation('signUpTerm');
  return (
    <Modal onClose={onClose} maxWidth="444px">
      <Typography variant="h6" className={classes.dialogTitle}>
        {t('title')}
      </Typography>
      <div className={classes.content}>
        <Typography color="textSecondary" style={{ marginBottom: '12px' }}>
          {t('beforeSignUpMessage')}
        </Typography>
        <Typography component="h6" variant="subtitle1" className={classes.titleDialog}>
          {t('letClinicServiceTerms')}
        </Typography>
        <Typography>
          <Trans i18nKey="acceptTerms" ns="signUpTerm">
            <Link href="/terms/terms-of-service" className={classes.linkDialog} target="_blank">
              first node
            </Link>
            <Link href="/terms/terms-of-payment" className={classes.linkDialog} target="_blank">
              second node
            </Link>
          </Trans>
        </Typography>
      </div>
      <div className={classes.buttons}>
        <Button onClick={onCancel} variant="outlined" color="secondary">
          {t('reject')}
        </Button>
        <Button onClick={onAccept} variant="contained" color="secondary" type="submit">
          {t('accept')}
        </Button>
      </div>
    </Modal>
  );
};

DialogConfirm.propTypes = {
  classes: PropTypes.object.isRequired,
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(DialogConfirm);
