import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, Typography, withStyles, CircularProgress } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIosRounded';
import { Button } from '@lc/legacy/src/Components';
import { useSnackbar } from 'contexts';
import EmailTextField, { emailValidateSchema } from 'components/TextField/EmailTextField';
import Modal from 'components/Modal';
import clsx from 'clsx';
import * as yup from 'yup';
import api from 'api';

const ValidateSchema = yup.object().shape({
  email: emailValidateSchema,
});

const ResetPasswordModal = ({ classes, onBack, onClose }) => {
  const { t } = useTranslation(['changePassword', 'feedbacks', 'common']);
  const [, setMessage] = useSnackbar();
  const { register, handleSubmit, errors } = useForm({ validationSchema: ValidateSchema });
  const [email, setEmail] = useState('');
  const [fetching, setFetching] = useState(false);

  const onSubmit = async () => {
    setFetching(true);
    const [, error] = await api.resetPassword(email);
    setFetching(false);
    if (error) {
      setMessage({ message: t('feedbacks:resetPasswordError'), variant: 'error' });
    } else {
      setMessage({ message: t('feedbacks:resetPasswordSuccess'), variant: 'info' });
      onClose();
    }
  };

  return (
    <Modal onClose={onClose} closeButton>
      <Typography variant="h6" component="h6" className={classes.title}>
        {t('resetPassword')}
      </Typography>
      <Typography component="p" align="center">
        {t('resetPasswordGuide')}
      </Typography>
      <EmailTextField
        showIcon={false}
        label={t('typeYourEmail')}
        value={email}
        error={errors.email}
        register={register}
        onChange={({ target: { value } }) => {
          setEmail(value);
        }}
        className={classes.input}
      />
      {fetching ? (
        <div className={clsx(classes.fetchingContainer, classes.buttonMargin)}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <Button
          fullWidth
          disableTouchRipple
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          className={classes.buttonMargin}
        >
          {t('sendResetLink')}
        </Button>
      )}
      {onBack && (
        /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
        <Link href="#" onClick={onBack} className={classes.backLink}>
          <ArrowBackIcon style={{ fontSize: '14px', marginRight: '2px' }} />
          {t('common:backToPage', { page: 'Login', context: 'male' })}
        </Link>
      )}
    </Modal>
  );
};

const styles = theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: '568px',
    padding: theme.spacing(12, 11, 5, 11),
    '&:focus': {
      outline: 'none !important',
      outlineOffset: 'none !important',
    },
  },
  title: {
    color: '#383838',
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  input: {
    marginTop: theme.spacing(5),
    '& input, & label': {
      fontSize: theme.typography.pxToRem(14),
    },
    '& svg': {
      width: '22px',
      marginRight: theme.spacing(2),
      color: 'hsla(0, 0%, 20%, .5)',
    },
  },
  fetchingContainer: {
    width: '100%',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
  },
  buttonMargin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2.1),
  },
  backLink: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(16),
  },
});

ResetPasswordModal.propTypes = {
  classes: PropTypes.object.isRequired,
  onBack: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

ResetPasswordModal.defaultProps = {
  onBack: undefined,
};

export default withStyles(styles)(ResetPasswordModal);
