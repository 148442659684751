import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// import Router from 'next/router'
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button } from '@lc/legacy/src/Components';

import styles from './index.style';

const RegisterWelcome = ({ classes, onNext }) => {
  const { t } = useTranslation('register');
  const xsSize = useMediaQuery(useTheme().breakpoints.down('xs'));

  return (
    <div style={{ textAlign: 'center' }}>
      <Typography variant={xsSize ? 'body1' : 'h6'} component="h3">
        <strong>{t('welcomeTo')}</strong>
      </Typography>
      <img
        src="/images/LetClinic_subtitle.png"
        style={{ width: xsSize ? '80px' : '146px', marginTop: '47px', marginBottom: '47px' }}
        alt="Let Clinic"
      />
      <Typography variant={xsSize ? 'body2' : 'body1'} color="textPrimary" align="center">
        {t('welcomeMessage')}
      </Typography>
      <Button
        onClick={() => {
          onNext(() => true);
        }}
        fullWidth
        variant="contained"
        size={xsSize ? 'small' : 'large'}
        color="primary"
        className={classes.signupButton}
      >
        {t('nextStep')}
      </Button>
    </div>
  );
};

RegisterWelcome.propTypes = {
  classes: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default withStyles(styles)(RegisterWelcome);
