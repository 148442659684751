import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import FbIcon from 'public/images/icons/facebook_logo.svg';
import GoogleIcon from 'public/images/icons/google_logo.svg';
import LinkedInIcon from 'public/images/icons/linkedin_logo.svg';
import EmailIcon from '@material-ui/icons/MailOutline';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import { Button } from '@lc/legacy/src/Components';

const styles = theme => ({
  button: {
    height: '60px',
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    padding: theme.spacing(0, 2, 0, 2),
    color: '#383838',
    fontWeight: '600',
    fontFamily: 'Montserrat',
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    fontSize: theme.typography.pxToRem(16),
    cursor: 'pointer',
    backgroundColor: 'transparent',
    maxWidth: '400px',

    [theme.breakpoints.down('sm')]: {
      height: '40px',
      fontSize: theme.typography.pxToRem(12),
      maxWidth: '256px',
    },
    '&:hover': {
      color: theme.palette.primary.borderHover,
      borderColor: theme.palette.buttons.borderHover,
    },
  },
  verticalMargin: {
    margin: theme.spacing(1, 0),
  },
});

const OutlinedButton = ({ classes, text, verticalMargin, StartIcon, ...props }) => {
  const xsSize = useMediaQuery(useTheme().breakpoints.down('xs'));

  return (
    <Button
      type="button"
      className={clsx(classes.button, verticalMargin && classes.verticalMargin)}
      {...props}
    >
      <StartIcon style={{ width: xsSize && '16px' }} />
      {text}
    </Button>
  );
};

OutlinedButton.propTypes = {
  classes: PropTypes.object.isRequired,
  StartIcon: PropTypes.elementType,
  text: PropTypes.string.isRequired,
  verticalMargin: PropTypes.bool,
};
OutlinedButton.defaultProps = {
  verticalMargin: false,
  StartIcon: undefined,
};
export const StyledOutlinedButton = withStyles(styles)(OutlinedButton);

export const Facebook = props => <StyledOutlinedButton StartIcon={FbIcon} {...props} />;
export const Google = props => <StyledOutlinedButton StartIcon={GoogleIcon} {...props} />;
export const LinkedIn = props => <StyledOutlinedButton StartIcon={LinkedInIcon} {...props} />;
export const Email = props => <StyledOutlinedButton StartIcon={EmailIcon} {...props} />;

export default {
  OutlinedButton: StyledOutlinedButton,
  Facebook,
  Google,
  LinkedIn,
  Email,
};
