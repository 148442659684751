const styles = theme => ({
  dialogTitle: {
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(1),
  },
  content: {
    paddingTop: 0,
  },
  titleDialog: {
    fontWeight: 'bold',
  },
  linkDialog: {
    color: theme.palette.secondary.main,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    marginTop: '12px',
  },
});

export default styles;
