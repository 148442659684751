import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Router from 'next/router';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import useTheme from '@material-ui/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useUserInfo } from '@lc/lib/Provider';

import api from 'api';
import routes from 'config/routes';

import { url, logger, register, gtag } from 'helpers';
import { useSnackbar } from 'contexts';

import Head from 'components/Head';
import Spinner from 'components/Spinner';
import Layout from 'components/Layout';
import SignUpTermsDialog from 'components/SignUpForm/SignUpTermsDialog';
import ValidationOnScreenModal from 'components/ValidationOnScreen/modal';

import Register from 'features/Register';

import ResetPasswordModal from 'features/ResetPassword/Components/ResetPasswordModal';

import LoginOptions from './components/LoginOptions';

import styles from './index.style';

const USER_CONFLICT_ALREADY_EXIST = 409;
const USER_BLOCKED = 403;

const Login = ({ classes, query }) => {
  const { t } = useTranslation();
  const [, setMessage] = useSnackbar();
  const { saveUserData, isLogged, userData } = useUserInfo();
  const xsSize = useMediaQuery(useTheme().breakpoints.down('xs'));

  const [resetPasswordVisible, setResetPasswordVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [signUpTermsVisible, setTermsVisibility] = useState(false);
  const [loginPayload, setLoginPayload] = useState(undefined);
  const [registerModalVisible, setRegisterModalVisibility] = useState(false);
  const [defaultLogin, setDefaultLogin] = useState('email');
  const [publicAccount, setPublicAccount] = useState();

  const handleLogin = async ({ loginRequest, mode }) => {
    const [response, error] = loginRequest;

    if (!error) {
      saveUserData(response);
      setTimeout(() => {
        if (mode === 'SIGNUP') {
          setRegisterModalVisibility(true);
        }
      }, 200);
    } else if (response.error.status === USER_CONFLICT_ALREADY_EXIST) {
      logger.logError('features/Login/Pages/index.js handleLogin()', response.error);

      setMessage({
        message: t('feedbacks:explainEmailAlreadyInUse'),
        variant: 'error',
      });
    } else {
      logger.logError('features/Login/Pages/index.js handleLogin() else', response);
    }
  };

  const handleSocialLogin = async ({ mode, ...payload }) => {
    if (mode === 'LOGIN') {
      await handleLogin(payload);
    } else if (mode === 'SIGNUP') {
      setTermsVisibility(true);
    }
  };

  const handleSuccess = useCallback(async payload => {
    setLoading(true);
    if (payload.type === 'email') {
      await handleLogin(payload);
    } else {
      handleSocialLogin(payload);
      setLoginPayload(payload);
    }
  }, []);

  const handleLoginWithEmail = async data => {
    setLoading(true);
    const [response, error] = await api.login(data.email, data.password);

    if (response?.error?.status === USER_BLOCKED) {
      setMessage({
        message: t('translations:blockedAccount'),
        variant: 'error',
      });
      setLoading(false);
      return false;
    }
    if (!error) {
      saveUserData(response);
      return true;
    }
    setMessage({
      message: t('translations:invalidCredencialsUserPassword'),
      variant: 'error',
    });
    setLoading(false);
    return false;
  };

  const validateEmail = async email => {
    const [emailResult, error] = await api.validateEmail(email);
    if (emailResult?.error?.status === USER_BLOCKED) {
      setMessage({
        message: t('translations:blockedAccount'),
        variant: 'error',
      });

      return false;
    }
    if (!error) {
      const { accountExisted, suggestedAuthMethod, publicAccountData } = emailResult;
      if (!accountExisted) {
        sessionStorage.setItem('email', email);
        Router.push(`${routes.signupForm}${window.location.search}`);
        return false;
      }
      if (accountExisted && suggestedAuthMethod === 'local') {
        return true;
      }
      setPublicAccount(publicAccountData);
      setDefaultLogin(suggestedAuthMethod);
    }
    return false;
  };

  const layoutProps = () => {
    if (xsSize) {
      return {
        showFooter: !loading,
        grayTheme: true,
      };
    }

    return {
      blueTheme: true,
      backgroundLogo: true,
    };
  };

  useEffect(() => {
    if (query.payload) {
      const payload = JSON.parse(window.atob(query.payload));
      handleSuccess(payload);
    }
    if (query?.returnUrl?.includes('/rating/')) {
      gtag.rating.login();
    }
    sessionStorage.removeItem('email');
  }, [handleSuccess, query.payload, query?.returnUrl]);

  useEffect(() => {
    if (isLogged) {
      if (register.isQuickRegister()) {
        url.removeFromQueryString('payload');
        if (
          userData?.occupation?.record_number === undefined ||
          userData?.occupation?.issuer_state === undefined ||
          userData?.occupation?.expertise_id?.id === undefined
        ) {
          Router.replace(`/signup-form${window.location.search}`);
        } else {
          gtag.minimumUserQuickRegister();
          const rUrl = url.shouldRedirectToAnotherPage() || '/';
          Router.replace(rUrl);
        }
      } else {
        const rUrl = url.shouldRedirectToAnotherPage() || '/';
        Router.replace(rUrl);
      }
    }
    if (sessionStorage.getItem('blockedAccount')) {
      setMessage({
        message: t('translations:blockedAccount'),
        variant: 'error',
      });
      sessionStorage.removeItem('blockedAccount');
    }
  }, [
    isLogged,
    setMessage,
    t,
    userData?.occupation?.expertise_id?.id,
    userData?.occupation?.issuer_state,
    userData?.occupation?.record_number,
  ]);

  return (
    <>
      {registerModalVisible && <Register isOpen />}
      <ValidationOnScreenModal query={query} />
      <Head pageTitle="Login" />
      <Layout {...layoutProps()} noPadding={xsSize}>
        {loading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '100px',
            }}
          >
            <Spinner />
          </div>
        ) : (
          <div style={{ padding: !xsSize && '80px 0px' }}>
            <Grid container justify="center" alignItems="center" spacing={0}>
              <Grid item xs={12} lg={8} container justify="center" spacing={0}>
                <Paper className={classes.buttonsContainer} elevation={0}>
                  <Typography variant="h5" color="primary" className={classes.buttonsTitle}>
                    Entrar ou Cadastrar-se
                  </Typography>

                  <LoginOptions
                    publicAccount={publicAccount}
                    defaultLogin={defaultLogin}
                    setDefaultLogin={setDefaultLogin}
                    validateEmail={validateEmail}
                    handleLoginWithEmail={handleLoginWithEmail}
                    passwordModal={() => setResetPasswordVisibility(true)}
                  />
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}

        {signUpTermsVisible && (
          <SignUpTermsDialog
            onClose={() => {
              setTermsVisibility(false);
            }}
            onAccept={async () => {
              await handleLogin(loginPayload);
              setTermsVisibility(false);
            }}
            onCancel={() => {
              setLoginPayload(undefined);
              setTermsVisibility(false);
            }}
          />
        )}
        {resetPasswordVisible && (
          <ResetPasswordModal
            onBack={() => {
              setResetPasswordVisibility(false);
            }}
            onClose={() => {
              setResetPasswordVisibility(false);
            }}
          />
        )}
      </Layout>
    </>
  );
};

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);
